import Drawer from '@/components/drawers'
import Loading from '@/components/widgets/Loading.vue'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    keyword:{
      type: String,
    },
    keyword_lot:{
      type: String,
    },
    keyword_color:{
      type: String,
    },
    keyword_thick:{
      type: String,
    },
    keyword_brand:{
      type: String,
    },
    keyword_code:{
      type: String,
    },
    keyword_row:{
      type: String,
    },    
    keyword_warehouse:{
      type: String,
    },
    selectedId:{
      type: String,
    }
  },
  data() {
    return {
      loading: false,
      skeleton: false,
      drawerOpened: false,
      form: {
        price: null
      }
    }
  },
  components: {
    Loading,
    Drawer
  },
  mixins: [validationMixin],
  validations: {
    form: {
      price: { required },
    }
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.drawerOpened = n
        this.skeleton = true
        setTimeout(() => {
          this.skeleton = false
        }, 1000)
      }
    }
  },
  methods: {
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.$v.$reset()
      this.$emit('close-event', { 
        status: true
      })
    },
    async onSubmitForm() {
      try {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }

        this.loading = true
        const formData = new FormData()
        formData.append('price', this.form.price)
        await this.$axios.post(this.$host + "/api/v1/updatePrice?keyword=" + this.keyword
        + "&keyword_lot=" + this.keyword_lot
        + "&keyword_color=" + this.keyword_color
        + "&keyword_thick=" + this.keyword_thick
        + "&keyword_brand=" + (this.keyword_brand ? this.keyword_brand : "")
        + "&keyword_code=" + this.keyword_code
        + "&keyword_row=" + this.keyword_row
        + "&keyword_warehouse=" + this.keyword_warehouse
        , formData,{
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          }
        })
        
        this.loading = false
        this.$swal({
          width: "29rem",
          padding: '1.825em',
          html: '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
            '<div class="d-flex flex-column">'+
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
            `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>`+
            '</div>'+
          '</div>',
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: 'swal2-custom-rounded',
              closeButton: 'text-3xl swal2-custom-close',
              actions: 'justify-content-end',
              contant: 'd-flex flex-row justify-content-around px-2',
              title: 'd-none',
              confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
            },
            showCancelButton: false,
            confirmButtonText: "ตกลง",
            cancelButtonText: "ยกเลิก",
            showCloseButton: true,
        }).then(result => {
          this.form.price = null
          this.onCloseDrawer()
        })
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}